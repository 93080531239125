<template>
  <div class="login">
  <div class="box">

    <div class="login-box">
      <div class="title">
        <img src="../assets/image/logo.png" alt="" />
        <span>牧予生活营销管理系统</span>
      </div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="0"
        class="demo-ruleForm"
      >
        <el-form-item label="" prop="username">
          <el-input
            v-model="ruleForm.username"
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="password">
          <el-input
            v-model="ruleForm.password"
            type="password"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
        <el-form-item class="loginBtn">
          <el-button type="primary" @click="submitForm()">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="shadow1"></div>
    <div class="shadow2"></div>
        
  </div>
  <div class="tips">
    <img src="../assets/image/wh.png" alt="">
    <span>技术支持:华有科技</span>
  </div>
  </div>
</template>
<script>
export default {
  name: "login",
  data() {
    return {
      ruleForm: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  mounted(){
      document.body.addEventListener('keydown',(e)=>{
        if(e.key == 'Enter'){
          this.submitForm()
        }
      })
  },
  methods: {
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$request
            .HttpPost("/admin/base/login", this.ruleForm)
            .then((res) => {
              window.localStorage.setItem("token", res.data.token);
              this.$store.commit('setUserInfo',res.data.user)
              this.$router.push({ path: "/index" });
            });
        }
      });
      // this.$router.push({ path: '/client/list' });
    },
  },
};
</script>
<style lang="scss" scoped>
.login {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  // background: url("../assets/image/bg.png");
  background: #f3f3f3;
  background-repeat: no-repeat;
  background-size: 100vw 100vh;
  position: relative;
  .box{
    width: calc(100vw / 2 + 260px);
    height: calc((100vh / 2) + 330px);
    background:linear-gradient(to right, #F0F0F0,#E5E5E5,#E7E7E7,#EBEBEB 30%) ;
    // background: red;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-210px, -260px);
    // background: red;
  }
  .login-box {
    width: 520px;
    height: 420px;
    background: #ffffff;
    border: 1px solid #c8c8c8;
    padding: 50px;
    background: #ffffff;
    box-sizing: border-box;
    // position: absolute;
    // left: 0;
    // top: 0;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%,-50%);
    .title {
      display: flex;
      align-items: flex-end;
      margin-bottom: 59px;
      img {
        width: 40px;
        height: 60px;
        margin-right: 10px;
      }
      span {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
      }
    }
  }
  .shadow1{
    width: 0;
    height: 0;
    border-top: 1px solid transparent;
    border-bottom: calc((100vh - 420px) / 2 + 100px) solid #f3f3f3;
    border-right: calc((100vw - 520px) / 2 + 680px) solid transparent;
    position: absolute;
    top:420px;
    left: 0;
    // transform: translate(-50%,-50%);
  }
  .shadow2{
     width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 520px;
    border-top: 100px solid #f3f3f3;
    border-left: calc((100vw - 420px) / 2) solid transparent;
  }
  .loginBtn {
    .el-button {
      width: 380px;
      height: 42px;
      background: #00923f;
      font-size: 14px;
    }
  }
}
.tips{
  position: fixed;
  right: 50%;
  bottom: 10%;
  transform: translate(50%,0);
  display: flex;
  align-items: center;
  img{
    width: 10px;
    height: 11px;
    margin-right: 5px;
    vertical-align: middle;
  }
  font-size: 12px;
font-family: PingFang SC;
font-weight: 500;
color: #999999;
}
/deep/ .el-input__inner {
  width: 380px;
  height: 42px;
  font-size: 12px;
}
</style>
